<template>
  <div>
    <v-row justify="center">
      <v-dialog
          v-model="dialogAddPhoneticToTitleQuestion"
          persistent
          max-width="750px"
          scrollable
      >
        <v-card>
          <v-card-title>
            <v-col cols="11">
              <span class="headline">Thêm phiên âm</span>
            </v-col>
            <v-col cols="1">
              <v-icon @click="dialogAddPhoneticToTitleQuestion = false" right
              >mdi-close</v-icon
              >
            </v-col>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" class="pt-0">
                  <p class="mb-0 text-body-1">Bổ sung dấu cách giữa các từ có nghĩa trước khi generate phiên âm</p>
                  <v-textarea
                      outlined dense
                      v-model.trim="chinese_character_input"
                  />
                  <div
                      id="btnConvertPinyin"
                      class="p-3 text-h6 rounded cursor-pointer mb-5 font-weight-bold"
                    >
                    Convert to Pinyin
                  </div>
                  <v-textarea
                      outlined dense
                      v-model.trim="chinese_pinyin_input"
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                color="darken-1" text
                @click="dialogAddPhoneticToTitleQuestion = false"
            >
              Đóng
            </v-btn>
            <v-btn
                color="blue darken-1" class="font-weight-bold"
                text outlined
                @click="btnSavePinyin()"
            >
              Cập nhật
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "AddPhoneticToTitleQuestionModal",
  props: {

  },
  data() {
    return {
      chinese_character_input: "",
      chinese_pinyin_input: "",
    }
  },
  computed: {
    is_call_api: {
      get() {
        return this.$store.state.informationTestBuilderStore.is_call_api;
      },
      set(value) {
        this.$store.commit("informationTestBuilderStore/setIsCallApi", value);
      },
    },
    dialogAddPhoneticToTitleQuestion: {
      get() {
        return this.$store.state.contentTestBuilderStore.dialogAddPhoneticToTitleQuestion;
      },
      set(value) {
        this.$store.commit("contentTestBuilderStore/setDialogAddPhoneticToTitleQuestion", value);
      },
    },
    refTitleQuestion: {
      get() {
        return this.$store.state.contentTestBuilderStore.refTitleQuestion;
      },
      set(value) {
        this.$store.commit("contentTestBuilderStore/setRefTitleQuestion", value);
      },
    },
  },
  methods: {
    btnSavePinyin() {
      // validate
      // end validate

      let insertFirstChineseCharacter = '<ruby>';
      let insertLastChineseCharacter = '</ruby>';

      let insertFirstChinesePinyin = '<rt>';
      let insertLastChinesePinyin = '</rt>';

      let editor = this.refTitleQuestion.$_instance;
      // let offsetCurson = editor.model.document.selection.getFirstPosition().offset;

      let data_phonetic =
          " " +
          insertFirstChineseCharacter +
          this.chinese_character_input +
          insertFirstChinesePinyin +
          this.chinese_pinyin_input +
          insertLastChinesePinyin +
          insertLastChineseCharacter;

      editor.model.change((writer) => {
        console.log(writer)
        let insertPosition = editor.model.document.selection.getFirstPosition();
        writer.insertText(data_phonetic, insertPosition);
      });
      // this.refSingleChoiceInParagraph.$_instance.editing.view.focus();
      this.dialogAddPhoneticToTitleQuestion = false;
    },
  }

}
</script>

<style scoped>
#btnConvertPinyin {
  width: 100%;
  text-align: center;
  align-items: center;
  background-color: #29B6F6;
  color: white;
  border: 1px solid #29B6F6;
}
#btnConvertPinyin:hover {
  background-color: white;
  color: #29B6F6;
}
</style>