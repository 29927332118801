<template>
  <div>
    <v-row justify="center">
      <v-dialog
          v-model="dialogSortSkill"
          persistent
          max-width="600px"
          scrollable
      >
        <v-card>
          <v-card-title>
            <v-col cols="11">
              <span class="headline">Sắp xếp thứ tự kĩ năng</span>
            </v-col>
            <v-col cols="1">
              <v-icon @click="dialogSortSkill = false" right
              >mdi-close</v-icon
              >
            </v-col>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" class="p-0">
                  <draggable :list="data_draggable">
                    <ul
                        class="list-unstyled cursor-pointer"
                        v-for="(skill, i) in data_draggable"
                        :key="i"
                    >
                      <b-media tag="li">
                        <h4 class="mt-0 mb-1 font-weight-bold">
                          {{ skill.skill_type }}
                        </h4>
                      </b-media>
                    </ul>
                  </draggable>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="darken-1" text @click="dialogSortSkill = false">Đóng</v-btn>
            <v-btn
                color="blue darken-1"
                class="font-weight-bold"
                text outlined
                @click="btnSortSkill"
            >
              Cập nhật</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>

<script>
import {mapGetters, mapState} from "vuex";
import draggable from "vuedraggable";
import Swal from "sweetalert2";
import ApiService from "@/service/api.service";

export default {
  name: "SortSkillModal",
  components: {
    draggable,
  },
  data() {
    return {
      data_draggable: [],
    };
  },
  computed: {
    ...mapState({}),
    ...mapGetters({
      skills: "contentTestBuilderStore/skills",
    }),
    is_call_api: {
      get() {
        return this.$store.state.informationTestBuilderStore.is_call_api;
      },
      set(value) {
        this.$store.commit("informationTestBuilderStore/setIsCallApi", value);
      },
    },
    dialogSortSkill: {
      get() {
        return this.$store.state.contentTestBuilderStore.dialogSortSkill;
      },
      set(value) {
        this.$store.commit("contentTestBuilderStore/setDialogSortSkill", value);
      },
    },
  },
  watch: {
    dialogSortSkill: function (val) {
      if (val) {
        this.setDataInput();
      }
    },
  },
  methods: {
    setDataInput() {
      let data_skill = this.skills;
      this.data_draggable = JSON.parse(JSON.stringify(data_skill));
    },
    btnSortSkill() {
      let vm = this;
      Swal.fire({
        title: "Are you sure you want to change?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, do it!",
      }).then((result) => {
        if (result.value) {
          let data_sort = vm.data_draggable.map((skill, index) => {
            return {
              mapping_id: skill.id_skill_test_mapping,
              offset: index + 1,
            };
          });
          let data = {
            sorts: data_sort,
          };
          vm.is_call_api = true;
          ApiService.post("prep-app/test/draft/skill/sort", data)
              .then(function (res) {
                console.log(res);
                if (res.status === 200) {
                  for (let iSkill = 0; iSkill < vm.skills.length; iSkill++) {
                    for (let iSort = 0; iSort < data_sort.length; iSort++) {
                      if (vm.skills[iSkill].id_skill_test_mapping === data_sort[iSort].mapping_id) {
                        vm.skills[iSkill].offset = data_sort[iSort].offset;
                      }
                    }
                  }

                  vm.skills.sort(function (x, y) {
                    return x.offset - y.offset;
                  });

                  vm.$set(vm.skills);
                  vm.is_call_api = false;
                  vm.$toasted.success("Sắp xếp kĩ năng thành công !!", {theme: "toasted-primary", position: "top-right", duration: 4000,});
                  vm.dialogSortSkill = false;
                }
              })
              .catch(function (error) {
                if (error) {
                  vm.is_call_api = false;
                  vm.$toasted.error("Something Wrong. Please try again or contact admin !!", {theme: "toasted-primary", position: "top-right", duration: 4000,});
                }
              });


        }
      });

    }
  }

}
</script>

<style scoped>

</style>