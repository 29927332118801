import constantTestBuilder from "./constantTestBuilder";

export default {
  mixins: [
    constantTestBuilder,
  ],
  data() {
    return {

    }
  },
  computed: {

  },
  methods: {
    convertDataSkill(skills) {
      if (skills.length) {
        for (let iK = 0; iK < skills.length; iK++) {
          skills[iK].skill_type = skills[iK].name;
          delete skills[iK].name;
          skills[iK].currentTabSection = 0;
          skills[iK].totalSection = skills[iK].sections.length;
          skills[iK].sections = this.convertDataSection(skills[iK].sections);
        }
      }
      return skills;
    },
    convertDataSection(sections) {
      for (let iS = 0; iS < sections.length; iS++) {
        sections[iS].section_title = sections[iS].title;
        sections[iS].section_description = sections[iS].content;
        sections[iS].element_data = this.transformerElement(sections[iS].element).element_data;
        sections[iS].element_type = this.transformerElement(sections[iS].element).element_type;
        sections[iS].element_id = this.transformerElement(sections[iS].element).element_id;
        sections[iS].element_timestamp = this.transformerElement(sections[iS].element).element_timestamp;
        sections[iS].element_slow_sound = this.transformerElement(sections[iS].element).element_slow_sound;
        sections[iS].is_element_pinned = sections[iS].is_element_pinned !== 0;
        sections[iS].sub_element_data = this.transformerElement(sections[iS].sub_element).element_data;
        sections[iS].sub_element_type = this.transformerElement(sections[iS].sub_element).element_type;
        sections[iS].sub_element_id = this.transformerElement(sections[iS].sub_element).element_id;
        delete sections[iS].title;
        delete sections[iS].content;
        sections[iS].exercises = this.convertDataExercise(sections[iS].exercises);
        sections[iS].total_question = this.calculatorTotalQuesOfSec(sections[iS]);
        sections[iS].offset_question = this.recursiveOffsetQuesOfSec(sections, iS);
      }
      return sections;
    },
    recursiveOffsetQuesOfSec(sections, index) {
      let offset = 0;
      if (index === 0) {
        return 0;
      }
      offset = sections[index - 1].total_question;
      return offset + this.recursiveOffsetQuesOfSec(sections, index - 1);
    },
    calculatorOffsetQuesOfSec(sections, indexSection) {
      let offset = 0;
      if (indexSection === 0) {
        offset = 0;
      } else {
        for (let x = 0; x < indexSection; x++) {
          for (let y = 0; y < sections[x].exercises.length; y++) {
            offset += sections[x].exercises[y].total_question;
          }
        }
      }
      return offset;
    },
    calculatorTotalQuesOfSec(section) {
      let total = 0;
      for (let iExer = 0; iExer < section.exercises.length; iExer++) {
        total += section.exercises[iExer].total_question;
      }
      return total;
    },
    transformerElement(element) {
      let data = {
        element_timestamp: [],
        element_data: null,
        element_type: null,
        element_id: null,
        description: null,
        element_slow_sound: null,
      };
      if (element) {
        let check = this.getLabelByTypeElement(element.type);
        data.element_type = this.getLabelByTypeElement(element.type);
        data.element_id = element.id;
        data.element_timestamp = element.timestamp_video;
        data.description = element.description;
        data.element_slow_sound = element.slow_sound;
        if (check === "Post" || check === "Embed_yt") {
          data.element_data = element.content;
        } else if (check === "Album") {
          data.element_data = element.photos;
        } else if (check === "Video") {
          // vimeo
          data.element_data = element.media_path;

          // muse
          // data.element_data = element.muse_video_id;
        } else {
          // } else if(check === "Pdf" || check === "Image" || check === "Audio") {
          data.element_data = element.media_path;
        }
      }
      return data;
    },
    convertDataExercise(exercises) {
      for (let iE = 0; iE < exercises.length; iE++) {
        exercises[iE].exercise_title = exercises[iE].title;
        exercises[iE].exercise_description = exercises[iE].content;
        // exercises[iE].exercise_type = this.getLabelTypeByValueExercise(exercises[iE].type);
        exercises[iE].exercise_type = exercises[iE].type;
        exercises[iE].element_data = this.transformerElement(exercises[iE].element).element_data;
        exercises[iE].element_type = this.transformerElement(exercises[iE].element).element_type;
        exercises[iE].element_id = this.transformerElement(exercises[iE].element).element_id;
        exercises[iE].element_description = this.transformerElement(exercises[iE].element).description;
        exercises[iE].element_slow_sound = this.transformerElement(exercises[iE].element).element_slow_sound;
        exercises[iE].is_element = exercises[iE].element == null ? false : true;
        exercises[iE].sub_element_data = this.transformerElement(exercises[iE].sub_element).element_data;
        exercises[iE].sub_element_type = this.transformerElement(exercises[iE].sub_element).element_type;
        exercises[iE].sub_element_id = this.transformerElement(exercises[iE].sub_element).element_id;
        exercises[iE].total_curly_braces = this.countCurlyBraces(exercises[iE].body, exercises[iE].type);
        exercises[iE].body = this.convertBodyExercise(exercises[iE].body, exercises[iE].type);
        exercises[iE].audio_timer = exercises[iE].element_timer;
        delete exercises[iE].title;
        delete exercises[iE].content;
        delete exercises[iE].type;
        delete exercises[iE].element;
        exercises[iE].total_question = this.calculatorTotalQuesOfExer(exercises[iE]);
        exercises[iE].offset_question = this.recursiveOffsetQuesOfExer(exercises, iE);
        console.log(exercises[iE].related_units);
        exercises[iE].related_unit_ids = exercises[iE].related_units.map(unit => unit.course_unit_id);
        if (exercises[iE].related_unit_ids.length) {
          exercises[iE].related_unit_ids = this.$utils.uniqueArray(exercises[iE].related_unit_ids);
        }
        exercises[iE].questions = this.convertDataQuestion(exercises[iE].questions, exercises[iE].exercise_type);
      }
      return exercises;
    },
    recursiveOffsetQuesOfExer(exercises, index) {
      let offset = 0;
      if (index === 0) {
        return 0;
      }
      offset = exercises[index - 1].total_question;
      return offset + this.recursiveOffsetQuesOfExer(exercises, index - 1);
    },
    countCurlyBraces(body, type) {
      let total = 0;
      if (type === this.exercise_type_constant.SHORT_ANSWER) {
        total = (body.match(/{{(.*?)}}/g) || []).length;
      }
      return total;
    },
    convertBodyExercise(body, type) {
      // let check_type = this.getLabelTypeByValueExercise(type);
      if ([
        this.exercise_type_constant.MATCHING,
        this.exercise_type_constant.ANSWER_IN_ANY_ORDER,
        this.exercise_type_constant.SINGLE_CHOICE_IN_PARAGRAPH,
        this.exercise_type_constant.MATCHING_PAIR,
        this.exercise_type_constant.MOCK_CONVERSATION,
      ].includes(type)) {
        body = JSON.parse(body);
      }

      return body;
    },
    calculatorTotalQuesOfExer(exercise) {
      let total = 0;
      if (exercise.exercise_type === this.exercise_type_constant.SHORT_ANSWER) {
        total += exercise.total_curly_braces;
      } else if (exercise.exercise_type === this.exercise_type_constant.MATCHING) {
        total += exercise.body.correct.length;
      } else if (exercise.exercise_type === this.exercise_type_constant.ANSWER_IN_ANY_ORDER) {
        total += exercise.body.length;
      } else if (exercise.exercise_type === this.exercise_type_constant.MATCHING_PAIR) {
        total += exercise.body.questions.length;
      } else {
        total += exercise.questions.length;
      }
      return total;
    },
    convertDataQuestion(questions, exercise_type) {
      for (let iQ = 0; iQ < questions.length; iQ++) {
        // questions[iQ].description     = questions[iQ].sample_content;
        questions[iQ].answer_id = questions[iQ].answers.length > 0 ? questions[iQ].answers[0].id : null;
        questions[iQ].body = this.convertBodyQuestion(questions[iQ].body, questions[iQ].type);
        questions[iQ].exercise_type = exercise_type;
        questions[iQ].element_data = this.transformerElement(questions[iQ].element).element_data;
        questions[iQ].element_type = this.transformerElement(questions[iQ].element).element_type;
        questions[iQ].element_id = this.transformerElement(questions[iQ].element).element_id;
        questions[iQ].element_description = this.transformerElement(questions[iQ].element).description;
        questions[iQ].element_slow_sound = this.transformerElement(questions[iQ].element).element_slow_sound;
        questions[iQ].is_element = questions[iQ].element === null ? false : true;
        questions[iQ].sample_data = questions[iQ].sample_file;
        // questions[iQ].sample_file = null;
        questions[iQ].content;
        delete questions[iQ].element;
      }
      return questions;
    },
    convertBodyQuestion(body, type) {
      if (
        type === this.exercise_type_constant.SENTENCE_STRESSES ||
        type === this.exercise_type_constant.INDIVIDUAL_SOUNDS_AND_SENTENCE_STRESSES ||
        type === this.exercise_type_constant.SHADOWING ||
        type === this.exercise_type_constant.SENTENCE_COMPLETION ||
        type === this.exercise_type_constant.SENTENCE_SCRAMBLE
      ) {
        body = JSON.parse(body);
      }
      return body;
    },

  },
  filters: {}
}