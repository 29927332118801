export default {
  computed: {
    weight_options: {
      get() {
        return this.$store.state.contentTestBuilderStore.listWeight;
      },
      set(value) {
        this.$store.commit("contentTestBuilderStore/setListWeight", value);
      },
    },
  },
  methods: {
    getValueByLabelWeight(label = '') {
      let result = this.weight_options.filter((e) => {
         return e.label.toLowerCase() === label.toLowerCase()
      })[0];
      return result ? result.value : null;
    },
    getLabelByValueWeight(value = 0) {
      let result = this.weight_options.filter((e) => e.value == value)[0];
      return result ? result.label : null;
    },
  },
  filters: {}
}