<template>
  <div>
    <v-row justify="center">
      <v-dialog
          v-model="dialogSortQuestion"
          persistent
          max-width="600px"
          scrollable
      >
        <v-card>
          <v-card-title>
            <v-col cols="11">
              <span class="headline"
              >Sắp xếp thứ tự câu hỏi cho exercise: {{ title_exercise }}</span
              >
            </v-col>
            <v-col cols="1">
              <v-icon @click="dialogSortQuestion = false" right
              >mdi-close</v-icon
              >
            </v-col>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" class="p-0">
                  <draggable :list="data_draggable">
                    <ul
                        class="list-unstyled drag-css cursor-pointer"
                        v-for="(question, i) in data_draggable"
                        :key="i"
                    >
                      <b-media tag="li">
                        <div
                            v-if=" !( exercise.exercise_type === exercise_type_constant.SENTENCE_STRESSES || exercise.exercise_type === exercise_type_constant.INDIVIDUAL_SOUNDS_AND_SENTENCE_STRESSES)"
                            v-html="question.title"
                          >
                        </div>
                        <h4
                            v-if="exercise.exercise_type === exercise_type_constant.SENTENCE_STRESSES || exercise.exercise_type === exercise_type_constant.INDIVIDUAL_SOUNDS_AND_SENTENCE_STRESSES"
                            class="mt-0 mb-1 font-weight-bold">
                          Câu hỏi thứ {{i+1}}
                        </h4>
                      </b-media>
                    </ul>
                  </draggable>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="darken-1" text @click="dialogSortQuestion = false"
            >Đóng</v-btn
            >
            <v-btn
                color="blue darken-1"
                class="font-weight-bold"
                text
                outlined
                @click="btnSortQuestion"
            >Cập nhật</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>

<script>
import draggable from "vuedraggable";
import {mapGetters, mapState} from "vuex";
import Swal from "sweetalert2";
import ApiService from "@/service/api.service";
import constantTestBuilder from "../mixins/Support/constantTestBuilder";

export default {
  name: "SortQuestionModal",
  components: {
    draggable,
  },
  mixins: [
      constantTestBuilder,
  ],
  data() {
    return {
      exercise: null,
      title_exercise: '',
      data_draggable: [],
    }
  },
  computed: {
    ...mapState({
      indexSkill: (state) => state.contentTestBuilderStore.indexSkill,
      indexSection: (state) => state.contentTestBuilderStore.indexSection,
      indexExercise: (state) => state.contentTestBuilderStore.indexExercise,
    }),
    ...mapGetters({
      skills: "contentTestBuilderStore/skills",
    }),
    is_call_api: {
      get() {
        return this.$store.state.informationTestBuilderStore.is_call_api;
      },
      set(value) {
        this.$store.commit("informationTestBuilderStore/setIsCallApi", value);
      },
    },
    dialogSortQuestion: {
      get() {
        return this.$store.state.contentTestBuilderStore.dialogSortQuestion;
      },
      set(value) {
        this.$store.commit("contentTestBuilderStore/setDialogSortQuestion", value);
      },
    },
  },
  watch: {
    dialogSortQuestion(val) {
      if(val) {
        this.setDataInput();
      }
    },
  },
  methods: {
    setDataInput() {
      let dataExercise = this.skills[this.indexSkill].sections[this.indexSection].exercises[this.indexExercise];
      this.exercise = dataExercise;
      this.title_exercise = dataExercise.exercise_title;
      this.data_draggable = [...dataExercise.questions];
      console.log(dataExercise);
    },
    btnSortQuestion() {
      let vm = this;
      Swal.fire({
        title: "Are you sure you want to change?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, do it!",
      }).then((result) => {
        if (result.value) {
          let data_sort = vm.data_draggable.map((exercise, index) => {
            return {
              question_id: exercise.id,
              index: index + 1,
            };
          });
          let data = {
            data_sort: data_sort,
          };
          vm.is_call_api = true;
          ApiService.post("prep-app/test/draft/question/sort", data)
              .then(function (res) {
                if (res.status == 200) {
                  let data_sort = vm.data_draggable.map((question, index) => {
                    return {
                      question_id: question.id,
                      index: index + 1,
                    };
                  });

                  for (let iQuestion = 0; iQuestion < vm.skills[vm.indexSkill].sections[vm.indexSection].exercises[vm.indexExercise].questions.length; iQuestion++) {
                    for (let iSort = 0; iSort < data_sort.length; iSort++) {
                      if (vm.skills[vm.indexSkill].sections[vm.indexSection].exercises[vm.indexExercise].questions[iQuestion].id === data_sort[iSort].question_id) {
                        vm.skills[vm.indexSkill].sections[vm.indexSection].exercises[vm.indexExercise].questions[iQuestion].index = data_sort[iSort].index;
                      }
                    }
                  }

                  vm.skills[vm.indexSkill].sections[vm.indexSection].exercises[vm.indexExercise].questions.sort(function (x, y) {
                    return x.index - y.index;
                  });

                  vm.$set(vm.skills);
                  vm.dialogSortQuestion = false;
                  vm.$toasted.success("Sort exercise successfully !!", {theme: "toasted-primary", position: "top-right", duration: 4000,});
                  vm.is_call_api = false;
                }
              })
              .catch(function (error) {
                if (error) {
                  vm.is_call_api = false;
                  vm.$toasted.error("Something Wrong. Please try again or contact admin !!", {theme: "toasted-primary", position: "top-right", duration: 4000,});
                }
              });
        }
      });
    },
  },
}
</script>

<style scoped>

</style>