<template>
  <div>
    <v-row justify="center">
      <v-dialog
          v-model="dialogQuestionUniversal"
          persistent
          max-width="1400px"
          scrollable
          :retain-focus="false"
      >
        <v-card>
          <v-card-title>
            <v-col cols="11">
              <span v-if="type_form_question_universal === 'add'" class="headline"
              >Thêm câu hỏi (Universal)</span
              >
              <span v-if="type_form_question_universal === 'update'" class="headline"
              >Cập nhật câu hỏi (Universal)</span
              >
            </v-col>
            <v-col cols="1">
              <v-icon @click="dialogQuestionUniversal = false" right
              >mdi-close</v-icon
              >
            </v-col>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                  <v-col cols="6">
                      <label class="label_default">Loại câu hỏi</label>
                    <v-select
                        :items="dataExerciseTypeForUniversalType"
                        item-text="label"
                        item-value="value"
                        v-model="typeQuestionInput"
                        dense outlined
                    />
                  </v-col>

                  <v-col cols="12" class="pt-0">
                      <label class="label_default">Tiêu đề</label>
                      <Editor v-model="titleQuestionInput" placeholder="Nhập tiêu đề câu hỏi"></Editor>
                      <div v-if="getNoteForTitle()">
                        <p class="mb-0 text-subtitle-1">Ghi chú: </p>
                        <pre class="grey--text d-block text-subtitle-1">{{getNoteForTitle().trim()}}</pre>
                      </div>
                  </v-col>

                  <v-col cols="12" class="pt-4" v-if="canUseContentInput()">
                      <label class="label_default">Mô tả</label>
                      <Editor ref="refContentQuestionUniversal" placeholder="Nhập mô tả câu hỏi" v-model="contentQuestionInput"></Editor>
                  </v-col>

                  <template v-if="typeQuestionInput === exercise_type_constant.SINGLE_CHOICE">
                    <SingleChoice
                        :option="optionAnswerSingleChoice"
                        :correct="correctSingleChoice"
                        @setCorrectAnswer="(e) => (correctSingleChoice = e)"
                        @setOptionAnswer="(e) => (optionAnswerSingleChoice = e)"
                    >
                    </SingleChoice>
                  </template>

                  <template v-if="typeQuestionInput === exercise_type_constant.MULTIPLE_CHOICE">
                    <MultipleChoice
                        class="pt-4"
                        :option="optionAnswerMultipleChoice"
                        @setOptionAnswer="(e) => (optionAnswerMultipleChoice = e)"
                    ></MultipleChoice>
                  </template>

                  <template v-if="typeQuestionInput === exercise_type_constant.SENTENCE_COMPLETION">
                    <v-col cols="12" class="pt-0 mb-8">
                      <SentenceCompletion
                          :ref-content-question-universal="$refs.refContentQuestionUniversal"
                          :corrects="dataCorrectSentenceCompletion"
                          @setDataCorrectSentenceCompletion="(e) => (dataCorrectSentenceCompletion = e)"
                          :obfuscates="dataObfuscateSentenceCompletion"
                          @setDataObfuscateSentenceCompletion="(e) => (dataObfuscateSentenceCompletion = e)"
                      ></SentenceCompletion>
                    </v-col>
                  </template>

                  <template v-if="typeQuestionInput === exercise_type_constant.SINGLE_CHOICE_ELEMENT">
                    <v-col cols="12">
                      <SingleChoiceElement
                        :option-content-type-prop="optionContentTypeInput"
                        @setOptionContentType="optionContentTypeInput = $event"
                        :option-layout-prop="optionLayoutTypeInput"
                        @setOptionLayoutType="optionLayoutTypeInput = $event"
                        :data-option-prop="dataOptionSingleChoiceElement"
                        @setDataOption="dataOptionSingleChoiceElement = $event"
                        :correct-index-prop="correctSingleChoiceElementIndex"
                        @setCorrectIndex="correctSingleChoiceElementIndex = $event"
                      />
                    </v-col>
                  </template>

                <template v-if="typeQuestionInput === exercise_type_constant.MATCHING_CARD">
                  <v-col cols="12">
                    <MatchingCard
                        :data-option-column-a-prop="dataOptionColumnAMatchingCard"
                        @setDataOptionColumnA="dataOptionColumnAMatchingCard = $event"
                        :type-content-column-a-prop="typeContentColumnAMatchingCard"
                        @setTypeContentColumnA="typeContentColumnAMatchingCard = $event"
                        :data-option-column-b-prop="dataOptionColumnBMatchingCard"
                        @setDataOptionColumnB="dataOptionColumnBMatchingCard = $event"
                        :type-content-column-b-prop="typeContentColumnBMatchingCard"
                        @setTypeContentColumnB="typeContentColumnBMatchingCard = $event"
                        :data-correct-prop="dataCorrectMatchingCard"
                        @setDataCorrect="dataCorrectMatchingCard = $event"
                    />
                  </v-col>
                </template>

                <template v-if="typeQuestionInput === exercise_type_constant.SENTENCE_SCRAMBLE">
                  <v-col cols="12">
                    <SentenceScramble
                        :data-word-prop="dataWordSentenceScramble"
                        @setDataWord="dataWordSentenceScramble = $event"
                        :data-obfuscate-prop="dataObfuscateSentenceScramble"
                        @setDataObfuscate="dataObfuscateSentenceScramble = $event"
                        :data-correct-prop="dataCorrectSentenceScramble"
                        @setDataCorrect="dataCorrectSentenceScramble = $event"
                    />
                  </v-col>
                </template>

                <v-col cols="12" class="" v-if="canUseExplanationInput()">
                  <label class="label_default">Explanation</label>
                  <Editor v-model="explanationQuestionInput"></Editor>
                </v-col>

                <v-col cols="12" class="m-0 p-0 pl-2">
                  <v-switch
                      v-model="toggle_element"
                      :label="`Thêm element: ${toggle_element.toString()}`"
                  ></v-switch>
                </v-col>
                <v-col cols="12" class="m-0 p-0 pl-2" v-if="toggle_element">
                  <ElementChoose
                    :has-config-slow-sound="canConfigSlowSoundElement()"
                  />
                </v-col>

              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="darken-1" text @click="dialogQuestionUniversal = false"
            >Đóng</v-btn
            >
            <v-btn
                outlined
                class="font-weight-bold"
                color="blue darken-1"
                text
                v-if="type_form_question_universal === 'add'"
                @click="btnAddQuestion"
            >Thêm</v-btn
            >
            <v-btn
                outlined
                class="font-weight-bold"
                color="blue darken-1"
                text
                v-if="type_form_question_universal === 'update'"
                @click="btnEditQuestion"
            >Cập nhật</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>

<script>
import constantTestBuilder from "../mixins/Support/constantTestBuilder";
import {mapGetters, mapState} from "vuex";
import ApiService from "@/service/api.service";
import SentenceCompletion from "./QuestionType/SentenceCompletion";

export default {
  name: "QuestionUniversalModal",
  mixins: [
      constantTestBuilder,
  ],
  components: {
    SentenceCompletion,
    Editor: () => import("@/components/ckeditor5/Editor"),
    SingleChoice: () => import("@/view/pages/tests/Modal/QuestionType/SingleChoice"),
    MultipleChoice: () => import("@/view/pages/tests/Modal/QuestionType/MultipleChoice"),
    SingleChoiceElement: () => import("@/view/pages/tests/Modal/QuestionType/SingleChoiceElement"),
    MatchingCard: () => import("@/view/pages/tests/Modal/QuestionType/MatchingCard"),
    ElementChoose: () => import("./ElementChoose"),
    SentenceScramble: () => import("@/view/pages/tests/Modal/QuestionType/SentenceScramble"),
  },
  data() {
    return {
      exercise: {},
      titleQuestionInput: "",
      explanationQuestionInput: "",
      contentQuestionInput: "",
      typeQuestionInput: null,
      offsetQuestionInput: 0,
      toggle_element: false,
      optionContentTypeInput: null,
      optionLayoutTypeInput: null,
      // single choice
      optionAnswerSingleChoice: [{ value: "" }, { value: "" }],
      correctSingleChoice: null,

      // multiple choice
      optionAnswerMultipleChoice: [{ value: "", checked: false }, { value: "", checked: false },],

      // sentence completion
      dataObfuscateSentenceCompletion: [{ uuid: null, value: null, offset: 1 }],
      dataCorrectSentenceCompletion: [],

      // single choice element
      dataOptionSingleChoiceElement: [{uuid: null, text: "", image_data: null, image_file: null, audio_data: null, audio_file: null}],
      correctSingleChoiceElementIndex: null,

      // matching card
      dataOptionColumnAMatchingCard: [{uuid: null, text: "", image_data: null, image_file: null, audio_data: null, audio_file: null, offset: 1}],
      typeContentColumnAMatchingCard: 1,
      dataOptionColumnBMatchingCard: [{uuid: null, text: "", image_data: null, image_file: null, audio_data: null, audio_file: null, offset: 1}],
      typeContentColumnBMatchingCard: 1,
      dataCorrectMatchingCard: [
        { columnAUuid: null, columnBUuid: null, columnAOffset: 1, columnBOffset: null,},
      ],

      // sentence scramble
      dataWordSentenceScramble: [{uuid: null, content: "", hashed: null, offset: 1}],
      dataObfuscateSentenceScramble: [{uuid: null, content: "", hashed: null, offset: 1}],
      dataCorrectSentenceScramble: [{ dataDraggableWord: [{uuid: null, content: "", hashed: null, offset: 1}]}],

    };
  },
  computed: {
    dataExerciseTypeForUniversalType() {
      return this.exercise_types.filter(
          (item) => this.listExerciseTypeInUniversalType().includes(item.value) || item.label === 'None'
      );
    },
    ...mapState({
      type_form_question_universal: (state) =>
          state.contentTestBuilderStore.type_form_question_universal,
      exercise_id: (state) => state.contentTestBuilderStore.exercise_id,
      question_id: (state) => state.contentTestBuilderStore.question_id,
      answer_id: (state) => state.contentTestBuilderStore.answer_id,
      indexSkill: (state) => state.contentTestBuilderStore.indexSkill,
      indexSection: (state) => state.contentTestBuilderStore.indexSection,
      indexExercise: (state) => state.contentTestBuilderStore.indexExercise,
      indexQuestion: (state) => state.contentTestBuilderStore.indexQuestion,
      product_category_id: (state) => state.informationTestBuilderStore.product_category_id,
    }),
    ...mapGetters({
      skills: "contentTestBuilderStore/skills",
    }),
    is_call_api: {
      get() {
        return this.$store.state.informationTestBuilderStore.is_call_api;
      },
      set(value) {
        this.$store.commit("informationTestBuilderStore/setIsCallApi", value);
      },
    },
    type_element: {
      get() {
        return this.$store.state.contentTestBuilderStore.type_element;
      },
      set(value) {
        this.$store.commit("contentTestBuilderStore/setTypeElement", value);
      },
    },
    element_post_data: {
      get() {
        return this.$store.state.contentTestBuilderStore.element_post_data;
      },
      set(value) {
        this.$store.commit("contentTestBuilderStore/setElementPostData", value);
      },
    },
    element_audio_data: {
      get() {
        return this.$store.state.contentTestBuilderStore.element_audio_data;
      },
      set(value) {
        this.$store.commit(
            "contentTestBuilderStore/setElementAudioData",
            value
        );
      },
    },
    element_image_data: {
      get() {
        return this.$store.state.contentTestBuilderStore.element_image_data;
      },
      set(value) {
        this.$store.commit(
            "contentTestBuilderStore/setElementImageData",
            value
        );
      },
    },
    element_audio_file: {
      get() {
        return this.$store.state.contentTestBuilderStore.element_audio_file;
      },
      set(value) {
        this.$store.commit(
            "contentTestBuilderStore/setElementAudioFile",
            value
        );
      },
    },
    element_image_file: {
      get() {
        return this.$store.state.contentTestBuilderStore.element_image_file;
      },
      set(value) {
        this.$store.commit("contentTestBuilderStore/setElementImageFile", value);
      },
    },
    element_has_slow_sound: {
      get() {
        return this.$store.state.contentTestBuilderStore.element_has_slow_sound;
      },
      set(value) {
        this.$store.commit("contentTestBuilderStore/setElementHasSlowSound", value);
      },
    },
    element_id: {
      get() {
        return this.$store.state.contentTestBuilderStore.element_id;
      },
      set(value) {
        this.$store.commit("contentTestBuilderStore/setElementId", value);
      },
    },
    element_form_display: {
      get() {
        return this.$store.state.contentTestBuilderStore.element_form_display;
      },
      set(value) {
        this.$store.commit(
            "contentTestBuilderStore/setElementFormDisplay",
            value
        );
      },
    },
    video_element_id: {
      get() {
        return this.$store.state.contentTestBuilderStore.video_element_id;
      },
      set(value) {
        this.$store.commit("contentTestBuilderStore/setVideoElementId", value);
      },
    },
    video_element_data: {
      get() {
        return this.$store.state.contentTestBuilderStore.video_element_data;
      },
      set(value) {
        this.$store.commit(
            "contentTestBuilderStore/setVideoElementData",
            value
        );
      },
    },
    element_description: {
      get() {
        return this.$store.state.contentTestBuilderStore.element_description;
      },
      set(value) {
        this.$store.commit("contentTestBuilderStore/setElementDescription", value);
      },
    },
    dialogQuestionUniversal: {
      get() {
        return this.$store.state.contentTestBuilderStore.dialogQuestionUniversal;
      },
      set(value) {
        this.$store.commit(
            "contentTestBuilderStore/setDialogQuestionUniversal",
            value
        );
      },
    },
  },
  watch: {
    dialogQuestionUniversal(val) {
      if (val) {
        this.setDataInput();
      }
    },
    typeQuestionInput(val) {
      if (val === this.exercise_type_constant.SINGLE_CHOICE_ELEMENT) {
        if (this.type_form_question_universal === "add") {
          this.optionContentTypeInput = this.question_content_type_constant.TEXT;
          this.optionLayoutTypeInput = this.question_layout_type_constant.GALLERY;
        }
      }
    },
    type_element(val, oldVal) {
      let vm = this;
      if (val) {
        if (this.dialogQuestionUniversal) {
          let type = this.getTypeByLabelElement(val);
          if (
              [
                this.exercise_type_constant.SINGLE_CHOICE,
                this.exercise_type_constant.MULTIPLE_CHOICE,
                this.exercise_type_constant.SENTENCE_COMPLETION,
                this.exercise_type_constant.MATCHING_CARD,
              ].includes( this.typeQuestionInput))
          {
            if (
                ![
                  this.element_type_constant.IMAGE,
                  this.element_type_constant.AUDIO,
                ].includes(type)
            ) {
              this.messError('Chỉ support cho 2 dạng element là image và audio');
              if (this.type_form_question_universal === "add") {
                vm.$nextTick(function() {
                  vm.type_element = oldVal;
                })
              }
              if (this.type_form_question_universal === "update") {
                vm.$nextTick(function() {
                  let old_element_type = vm.exercise.questions[vm.indexQuestion].element_type;
                  if (old_element_type) {
                    vm.type_element = old_element_type;
                  } else {
                    vm.type_element = oldVal;
                  }
                })
              }
            }
          }

          if (
              [
                this.exercise_type_constant.SINGLE_CHOICE_ELEMENT,
                this.exercise_type_constant.SENTENCE_SCRAMBLE,
              ].includes( this.typeQuestionInput))
          {
            if (!(type === this.element_type_constant.AUDIO)) {
              this.messError('Dạng câu hỏi này chỉ hỗ trợ element audio');
              vm.$nextTick(function() {
                vm.type_element = null;
              })
            }
          }
        }

      }
    },
    toggle_element(val) {
      if (val) {
        if (this.dialogQuestionUniversal) {

          if (!this.typeQuestionInput) {
            this.messError('Vui lòng chọn loại câu hỏi');
            let vm = this;
            vm.$nextTick(function() {
              vm.toggle_element = false;
            })
          } else {
            if (this.typeQuestionInput === this.exercise_type_constant.MATCHING_CARD) {
              this.messError('Dạng câu hỏi này không có element');
              let vm = this;
              vm.$nextTick(function() {
                vm.toggle_element = false;
              })
            }
          }

        }
      }
    },
  },
  methods: {
    getAlphabetCharacterFromIndex(index) {
      return String.fromCharCode(65 + index);
    },
    setDataInput() {
      let vm = this;
      let exercise = this.skills[this.indexSkill].sections[this.indexSection].exercises[this.indexExercise];
      this.exercise = JSON.parse(JSON.stringify(exercise));

      let type_form = this.type_form_question_universal;
      this.setDefaultAllOptionAndCorrectQuestion();
      this.setDefaultElementConfig();
      if (type_form === "add") {
        this.titleQuestionInput = "";
        this.explanationQuestionInput = "";
        this.contentQuestionInput = "";
        this.offsetQuestionInput = exercise.questions.length + 1;
        this.optionContentTypeInput = null;
        this.optionLayoutTypeInput = null;
        this.typeQuestionInput = null;
      }
      if (type_form === "update") {
        let data = this.skills[this.indexSkill].sections[this.indexSection].exercises[this.indexExercise].questions[this.indexQuestion];
        console.log(data);
        let type = data.type;
        this.titleQuestionInput = data.title;
        this.explanationQuestionInput = data.explanation ? data.explanation : '';
        this.contentQuestionInput = data.content ? data.content : '';
        this.typeQuestionInput = data.type;
        if (type === this.exercise_type_constant.SINGLE_CHOICE) {
          this.optionAnswerSingleChoice = JSON.parse(JSON.stringify(data.answers[0].option));
          this.correctSingleChoice = data.answers[0].correct;
        }
        if (type === this.exercise_type_constant.MULTIPLE_CHOICE) {
          this.optionAnswerMultipleChoice = JSON.parse(JSON.stringify(data.answers[0].option));
        }
        if (type === this.exercise_type_constant.SENTENCE_COMPLETION) {
          this.dataCorrectSentenceCompletion = JSON.parse(JSON.stringify(data.body.corrects));
          this.dataObfuscateSentenceCompletion = JSON.parse(JSON.stringify(data.body.obfuscates));
        }
        if (type === this.exercise_type_constant.SINGLE_CHOICE_ELEMENT) {
          let dataOption = JSON.parse(JSON.stringify(data.options));
          let optionContentType = Number(dataOption[0].content_type);
          let correctUuids = JSON.parse(data.correct_options);
          this.dataOptionSingleChoiceElement = dataOption.map((item, index) => {
            return {
              uuid: item.uuid,
              text: item.content,
              image_data: [vm.question_content_type_constant.IMAGE, vm.question_content_type_constant.TEXT_AND_IMAGE].includes(optionContentType) ? item.url : null,
              image_file: null,
              audio_data: optionContentType === vm.question_content_type_constant.AUDIO ? item.url : null,
              audio_file: null,
              offset: index + 1,
            };
          });

          this.correctSingleChoiceElementIndex = this.dataOptionSingleChoiceElement.filter((item) => correctUuids.includes(item.uuid))[0].offset - 1;
          this.optionContentTypeInput = optionContentType ? optionContentType : this.question_content_type_constant.TEXT;
          this.optionLayoutTypeInput = data.option_layout ? data.option_layout : this.question_layout_type_constant.GALLERY;
        }
        if (type === this.exercise_type_constant.MATCHING_CARD) {
          let dataOption = JSON.parse(JSON.stringify(data.options));
          this.dataOptionColumnAMatchingCard = dataOption.filter((item) => item.column === 1).map((item) => {
            return {
              uuid: item.uuid,
              text: item.content,
              image_data:  [vm.question_content_type_constant.IMAGE, vm.question_content_type_constant.TEXT_AND_IMAGE].includes(item.content_type) ? item.url : null,
              image_file: null,
              audio_data: item.content_type === vm.question_content_type_constant.AUDIO ? item.url : null,
              audio_file: null,
              offset: item.offset,
              content_type: item.content_type,
            };
          });
          this.typeContentColumnAMatchingCard = this.dataOptionColumnAMatchingCard[0].content_type;
          this.dataOptionColumnBMatchingCard = dataOption.filter((item) => item.column === 2).map((item) => {
            return {
              uuid: item.uuid,
              text: item.content,
              image_data: [vm.question_content_type_constant.IMAGE, vm.question_content_type_constant.TEXT_AND_IMAGE].includes(item.content_type) ? item.url : null,
              image_file: null,
              audio_data: item.content_type === vm.question_content_type_constant.AUDIO ? item.url : null,
              audio_file: null,
              offset: item.offset,
              content_type: item.content_type,
            };
          });
          this.typeContentColumnBMatchingCard = this.dataOptionColumnBMatchingCard[0].content_type;
          let correctUuids = JSON.parse(data.correct_options);
          this.dataCorrectMatchingCard = correctUuids.map((uuids) => {
            let objColumnA = vm.dataOptionColumnAMatchingCard.filter((item) => uuids.includes(item.uuid) )[0];
            let objColumnB = vm.dataOptionColumnBMatchingCard.filter((item) => uuids.includes(item.uuid) )[0];
            // console.log(objColumnA, objColumnB);
            return {
              columnAUuid: objColumnA.uuid,
              columnBUuid: objColumnB.uuid,
              columnAOffset: objColumnA.offset,
              columnBOffset: objColumnB.offset,
            };

            // let columnAUuid = uuids[0];
            // let columnBUuid = uuids[1];
            // let columnAOffset = vm.dataOptionColumnAMatchingCard.filter((item) => item.uuid === columnAUuid)[0].offset;
            // let columnBOffset = vm.dataOptionColumnBMatchingCard.filter((item) => item.uuid === columnBUuid)[0].offset;
            // return {
            //   columnAUuid: columnAUuid,
            //   columnBUuid: columnBUuid,
            //   columnAOffset: columnAOffset,
            //   columnBOffset: columnBOffset,
            // };

          });
        }
        if (type === this.exercise_type_constant.SENTENCE_SCRAMBLE) {
          let dataOption = JSON.parse(JSON.stringify(data.options));
          this.dataWordSentenceScramble = dataOption.filter((item) => !item.obfuscate).map((item) => {
            item.oldOffset = item.offset;
            return item;
          });
          this.dataObfuscateSentenceScramble = dataOption.filter((item) => item.obfuscate).map((item) => {
            item.oldOffset = item.offset;
            return item;
          });
          this.dataCorrectSentenceScramble = JSON.parse(JSON.stringify(data.body)).map((item) => {
            // set old offset
            item.dataDraggableWord = item.dataDraggableWord.map((item) => {
              item.oldOffset = item.offset;
              return item;
            });
            return item;
          });
        }

        this.type_element = data.element_type;
        this.element_post_data = data.element_type === "Post" ? data.element_data : "";
        this.element_image_data = data.element_type === "Image" ? data.element_data : null;
        this.element_audio_data = data.element_type === "Audio" ? data.element_data : null;
        this.video_element_data = data.element_type === "Video" ? data.element_data : null;
        this.element_audio_file = null;
        this.element_image_file = null;
        this.element_description = data.element_description ?? '';
        this.element_id = this.checkElementId(data.element_type, data.element_id);
        this.video_element_id = data.element_type === "Video" ? data.element_id : null;
        this.toggle_element = data.is_element;
        this.element_has_slow_sound = data.element_slow_sound === 1;
      }
    },
    checkElementId(type, element_id) {
      let check_element_id = element_id;
      if (type === "Video") {
        check_element_id = null;
      }
      return check_element_id;
    },
    setDefaultElementConfig() {
      this.toggle_element = false;
      this.type_element = null;
      this.element_post_data = null;
      this.element_image_data = null;
      this.element_audio_data = null;
      this.element_audio_file = null;
      this.element_image_file = null;
      this.element_id = null;
      this.video_element_data = null;
      this.video_element_id = null;
      this.element_has_slow_sound = false;
      this.element_description = '';
    },
    setDefaultAllOptionAndCorrectQuestion(){
      // config single choice to default
      this.correctSingleChoice = null;
      this.optionAnswerSingleChoice = [{ value: "" }, { value: "" }, { value: "" },];
      // end config single choice to default

      // config multiple choice to default
      this.optionAnswerMultipleChoice = [{ value: "", checked: false }, { value: "", checked: false },];
      // end config multiple choice to default

      // config sentence completion to default
      this.dataCorrectSentenceCompletion = [];
      this.dataObfuscateSentenceCompletion = [{ uuid: null, value: null, offset: 1 }];
      // end config sentence completion to default

      // config single choice element to default
      this.dataOptionSingleChoiceElement = [{uuid: null, text: "", image_data: null, image_file: null, audio_data: null, audio_file: null, offset: 1}];
      this.correctSingleChoiceElementIndex = null;
      // end config single choice element to default

      // config matching card to default
      this.dataOptionColumnAMatchingCard = [
          {uuid: null, text: "", image_data: null, image_file: null, audio_data: null, audio_file: null, offset: 1},
          {uuid: null, text: "", image_data: null, image_file: null, audio_data: null, audio_file: null, offset: 2}
      ];
      this.typeContentColumnAMatchingCard = 1; // 1: text, 2: image, 3: audio
      this.dataOptionColumnBMatchingCard = [
          {uuid: null, text: "", image_data: null, image_file: null, audio_data: null, audio_file: null, offset: 1},
          {uuid: null, text: "", image_data: null, image_file: null, audio_data: null, audio_file: null, offset: 2},
      ];
      this.typeContentColumnBMatchingCard = 1; // 1: text, 2: image, 3: audio
      this.dataCorrectMatchingCard = [
          { columnAUuid: null, columnBUuid: null, columnAOffset: 1, columnBOffset: null,},
          { columnAUuid: null, columnBUuid: null, columnAOffset: 2, columnBOffset: null,},
      ];
      // end config matching card to default

      // config sentence scramble to default
      this.dataWordSentenceScramble = [{uuid: null, content: "", hashed: null, offset: 1, oldOffset: 1,}];
      this.dataObfuscateSentenceScramble = [{uuid: null, content: "", hashed: null, offset: 1}];
      // this.dataCorrectSentenceScramble = [];
      this.dataCorrectSentenceScramble = [{ dataDraggableWord: [{uuid: null, content: "", hashed: null, offset: 1, oldOffset: 1,}]}];
      // end config sentence scramble to default
    },
    validateFormQuestion() {
      let flat = true;
      if (!this.typeQuestionInput) {
        this.messError("Vui lòng chọn loại câu hỏi");
        flat = false;
      }
      if (!this.titleQuestionInput) {
        this.messError("Vui lòng nhập tiêu đề câu hỏi");
        flat = false;
      }
      if (
          [
            this.exercise_type_constant.SINGLE_CHOICE_ELEMENT,
          ].includes(this.typeQuestionInput)
      ) {
        if (!this.optionLayoutTypeInput) {
          this.messError("Vui lòng chọn layout cho option");
          flat = false;
        }
        if (!this.optionContentTypeInput) {
          this.messError("Vui lòng chọn content type cho option");
          flat = false;
        }
      }

      if (
          [
            this.exercise_type_constant.SINGLE_CHOICE_ELEMENT,
            this.exercise_type_constant.SENTENCE_SCRAMBLE,
          ].includes(this.typeQuestionInput)
      )
      if (!this.element_audio_data && !this.contentQuestionInput) {
        this.messError('Với loại câu hỏi này, phải có ít nhất một trong hai thông tin: mô tả câu hỏi hoặc element audio');
        flat = false;
      }

      return flat;
    },
    validateOptionAndCorrectQuestionByType(type) {
      let flat = true;

      if (type === this.exercise_type_constant.SINGLE_CHOICE) {
        if (this.optionAnswerSingleChoice.length < 2) {
          this.messError("Phải có ít nhất 2 option");
          flat = false;
        }
        if (!this.correctSingleChoice) {
          this.messError("Vui lòng chọn đáp án đúng");
          flat = false;
        }
      }

      if (type === this.exercise_type_constant.MULTIPLE_CHOICE) {
        let countChecked = this.optionAnswerMultipleChoice.filter((item) => item.checked).length;
        if (countChecked < 1) {
          this.messError("Phải có ít nhất 1 option");
          flat = false;
        }
      }

      if (type === this.exercise_type_constant.SENTENCE_COMPLETION) {
        if (!this.contentQuestionInput) {
          this.messError("Hãy nhập dữ liệu cho question");
          flat = false;
        } else {
          if ((this.contentQuestionInput.match(/{{(.*?)}}/g) || []).length <= 0) {
            this.messError("Phải có tối thiểu 1 đáp án");
            flat = false;
          }
          let checkBracesOpen = (this.contentQuestionInput.match(/{{/g) || []).length;
          let checkBracesClose = (this.contentQuestionInput.match(/}}/g) || []).length;
          if (checkBracesOpen !== checkBracesClose) {
            this.messError("Các dấu ngoặc giá trị (đóng, mở) trong mô tả chưa đủ !!");
            flat = false;
          }
        }

        for (let i = 0; i < this.dataObfuscateSentenceCompletion.length; i++) {
          if (!this.dataObfuscateSentenceCompletion[i].value) {
            this.messError("Hãy nhập thông tin nhiễu thứ tự " + (i + 1));
            flat = false;
          }
        }
      }

      if (type === this.exercise_type_constant.SINGLE_CHOICE_ELEMENT) {
        if (this.dataOptionSingleChoiceElement.length > 4) {
          this.messError("Chỉ được thêm tối đa 4 option");
          flat = false;
        }
        if (this.optionLayoutTypeInput === this.question_layout_type_constant.GALLERY) {
         if (this.dataOptionSingleChoiceElement.length !== 4) {
           this.messError("Phải đủ 4 option với layout Gallery");
           flat = false;
         }
        }
        for (let i = 0; i < this.dataOptionSingleChoiceElement.length; i++) {
          if (this.optionContentTypeInput === this.question_content_type_constant.TEXT ) {
            if (!this.dataOptionSingleChoiceElement[i].text) {
              this.messError("Hãy nhập thông tin cho option " + (i + 1));
              flat = false;
            }
          }
          if ( [this.question_content_type_constant.IMAGE, this.question_content_type_constant.TEXT_AND_IMAGE].includes(this.optionContentTypeInput) ) {
            if (!this.dataOptionSingleChoiceElement[i].image_data) {
              this.messError("Hãy nhập image cho option " + (i + 1));
              flat = false;
            }
            if (this.dataOptionSingleChoiceElement[i].image_file) {
              if (this.dataOptionSingleChoiceElement[i].image_file.size > 2097152) {
                this.messError("Dung lượng ảnh option "+ (i + 1) +" không được vượt quá 2MB");
                flat = false;
              }

              let extFile = this.$utils.getExtensionByFile(this.dataOptionSingleChoiceElement[i].image_file);
              if (!['jpg', 'jpeg', 'png'].includes(extFile)) {
                this.messError("Option "+(i+1)+". File image không đúng định dạng: chỉ bao gồm jpg, jpeg, png");
                flat = false;
              }
            }
          }
          if (this.optionContentTypeInput === this.question_content_type_constant.AUDIO ) {
            if (!this.dataOptionSingleChoiceElement[i].audio_data) {
              this.messError("Hãy nhập audio cho option " + (i + 1));
              flat = false;
            }
            if (this.dataOptionSingleChoiceElement[i].audio_file) {
              if (this.dataOptionSingleChoiceElement[i].audio_file.size > 2097152) {
                this.messError("Dung lượng audio option "+ (i + 1) +" không được vượt quá 2MB");
                flat = false;
              }
              let extFile = this.$utils.getExtensionByFile(this.dataOptionSingleChoiceElement[i].audio_file);
              if (!["mp3", "wav", "ogg",].includes(extFile)) {
                this.messError("Option " + (i + 1) + ". File audio không đúng định dạng, chỉ bao gồm mp3, wav, ogg");
                flat = false;
              }
            }
          }
        }
        if (this.correctSingleChoiceElementIndex === null || this.correctSingleChoiceElementIndex === undefined) {
          this.messError("Vui lòng chọn đáp án đúng");
          flat = false;
        }
      }

      if (type === this.exercise_type_constant.MATCHING_CARD) {
        if (this.dataOptionColumnAMatchingCard.length !== this.dataOptionColumnBMatchingCard.length) {
          this.messError("Số lượng option 2 cột không bằng nhau");
          flat = false;
        }
        for (let i = 0; i < this.dataOptionColumnAMatchingCard.length; i++) {
          if (this.typeContentColumnAMatchingCard === this.question_content_type_constant.TEXT) {
            if (!this.dataOptionColumnAMatchingCard[i].text) {
              this.messError("Hãy nhập thông tin cho option " + (i + 1) + " cột A");
              flat = false;
            }
          }
          if ([this.question_content_type_constant.IMAGE, this.question_content_type_constant.TEXT_AND_IMAGE].includes(this.typeContentColumnAMatchingCard)) {
            if (!this.dataOptionColumnAMatchingCard[i].image_data) {
              this.messError("Hãy nhập image cho option " + (i + 1) + " cột A");
              flat = false;
            }
            if (this.dataOptionColumnAMatchingCard[i].image_file) {
              if (this.dataOptionColumnAMatchingCard[i].image_file.size > 2097152) {
                this.messError("Dung lượng ảnh option "+ (i + 1) +" cột A không được vượt quá 2MB");
                flat = false;
              }

              let extFile = this.$utils.getExtensionByFile(this.dataOptionColumnAMatchingCard[i].image_file);
              if (
                  ![
                    'jpg', 'jpeg', 'png'
                  ].includes(extFile)
              ) {
                this.messError("Cột A option "+(i+1)+". File image không đúng định dạng: chỉ bao gồm jpg, jpeg, png");
                flat = false;
              }
            }
          }
          if (this.typeContentColumnAMatchingCard === this.question_content_type_constant.AUDIO) {
            if (!this.dataOptionColumnAMatchingCard[i].audio_data) {
              this.messError("Hãy nhập audio cho option " + (i + 1) + " cột A");
              flat = false;
            }
            if (this.dataOptionColumnAMatchingCard[i].audio_file) {
              if (this.dataOptionColumnAMatchingCard[i].audio_file.size > 2097152) {
                this.messError("Dung lượng audio option "+ (i + 1) +" cột A không được vượt quá 2MB");
                flat = false;
              }
              let extFile = this.$utils.getExtensionByFile(this.dataOptionColumnAMatchingCard[i].audio_file);
              if (
                  ![
                    "mp3", "wav", "ogg",
                  ].includes(extFile)
              ) {
                this.messError("Cột A option " + (i + 1) + ". File audio không đúng định dạng, chỉ bao gồm mp3, wav, ogg");
                flat = false;
              }
            }
          }
        }
        for (let i = 0; i < this.dataOptionColumnBMatchingCard.length; i++) {
          if (this.typeContentColumnBMatchingCard === this.question_content_type_constant.TEXT) {
            if (!this.dataOptionColumnBMatchingCard[i].text) {
              this.messError("Hãy nhập thông tin cho option " + this.getAlphabetCharacterFromIndex(i) + " cột B");
              flat = false;
            }
          }
          if ([this.question_content_type_constant.IMAGE, this.question_content_type_constant.TEXT_AND_IMAGE].includes(this.typeContentColumnBMatchingCard )) {
            if (!this.dataOptionColumnBMatchingCard[i].image_data) {
              this.messError("Hãy nhập image cho option " + this.getAlphabetCharacterFromIndex(i) + " cột B");
              flat = false;
            }
            if (this.dataOptionColumnBMatchingCard[i].image_file) {
              if (this.dataOptionColumnBMatchingCard[i].image_file.size > 2097152) {
                this.messError("Dung lượng ảnh option "+ this.getAlphabetCharacterFromIndex(i) +" cột B không được vượt quá 2MB");
                flat = false;
              }
              let extFile = this.$utils.getExtensionByFile(this.dataOptionColumnBMatchingCard[i].image_file);
              if (
                  ![
                    'jpg', 'jpeg', 'png'
                  ].includes(extFile)
              ) {
                this.messError("Cột B option "+this.getAlphabetCharacterFromIndex(i)+". File image không đúng định dạng: chỉ bao gồm jpg, jpeg, png");
                flat = false;
              }
            }

          }
          if (this.typeContentColumnBMatchingCard === this.question_content_type_constant.AUDIO) {
            if (!this.dataOptionColumnBMatchingCard[i].audio_data) {
              this.messError("Hãy nhập audio cho option " + this.getAlphabetCharacterFromIndex(i) + " cột B");
              flat = false;
            }
            if (this.dataOptionColumnBMatchingCard[i].audio_file) {
              if (this.dataOptionColumnBMatchingCard[i].audio_file.size > 2097152) {
                this.messError("Dung lượng audio option "+ (i + 1) +" cột B không được vượt quá 2MB");
                flat = false;
              }
              let extFile = this.$utils.getExtensionByFile(this.dataOptionColumnBMatchingCard[i].audio_file);
              if (
                  ![
                    "mp3", "wav", "ogg",
                  ].includes(extFile)
              ) {
                this.messError("Cột B option "+this.getAlphabetCharacterFromIndex(i)+". File audio không đúng định dạng, chỉ bao gồm mp3, wav, ogg");
                flat = false;
              }
            }
          }
        }
        for (let i = 0; i < this.dataCorrectMatchingCard.length; i++) {
          if (!this.dataCorrectMatchingCard[i].columnBOffset) {
            this.messError("Hãy chọn đáp án tương ứng cho option " + (i + 1) + " cột A");
            flat = false;
          }
        }
      }

      if (type === this.exercise_type_constant.SENTENCE_SCRAMBLE) {
        for (let i = 0; i < this.dataWordSentenceScramble.length; i++) {
          if (!this.dataWordSentenceScramble[i].content.trim()) {
            this.messError("Hãy nhập thông tin cho từ đúng thứ " + (i + 1));
            flat = false;
          }
        }
        for (let i = 0; i < this.dataObfuscateSentenceScramble.length; i++) {
          if (!this.dataObfuscateSentenceScramble[i].content.trim()) {
            this.messError("Hãy nhập thông tin nhiễu thứ tự " + (i + 1));
            flat = false;
          }
        }
        if (!this.dataCorrectSentenceScramble.length) {
          this.messError("Chưa có dữ liệu về cấu hình đáp án đúng");
          flat = false;
        } else {
          let cpDataCorrectSentenceScramble = JSON.parse(JSON.stringify(this.dataCorrectSentenceScramble));
          let dataContentCorrect = cpDataCorrectSentenceScramble.map((item) => {
            return item.dataDraggableWord.map((item) => {
              return item.content.trim();
            });
          });

          let isConditionMet = false;
          for(let i = 0; i < dataContentCorrect.length; i++) {
            for(let j = i + 1; j < dataContentCorrect.length; j++) {
              if(JSON.stringify(dataContentCorrect[i]) === JSON.stringify(dataContentCorrect[j])) {
                this.messError("Cấu hình các đáp án đúng không được trùng nhau");
                flat = false;
                isConditionMet = true;
                break;
              }
            }
            if (isConditionMet) {
              break;
            }
          }

        }
      }
      return flat;
    },
    async btnAddQuestion() {
      let vm = this;
      let validateForm = this.validateFormQuestion();
      // if (!validateForm) {
      //   return;
      // }
      let validateQues = this.validateOptionAndCorrectQuestionByType(this.typeQuestionInput);
      // if (!validateQues) {
      //   return;
      // }
      let validatorElement = this.validateElementForm();
      // if (!validatorElement) {
      //   return;
      // }
      if (!(validateForm && validateQues && validatorElement)) {
        return;
      }
      if (vm.toggle_element) {
        await this.actionUploadElement();
      }

      let data = {
        exercise_id: this.exercise.id,
        title: this.titleQuestionInput,
        explanation: this.explanationQuestionInput ? this.explanationQuestionInput : null,
        exercise_type: this.exercise.exercise_type,
        type: this.typeQuestionInput,
        content: this.contentQuestionInput ? this.contentQuestionInput : null,
        weight: 0,
        element_type: this.type_element,
        element_data: this.getDataByLabelElement(this.type_element),
        element_id: this.toggle_element === false ? null : this.element_id,
        element_description: this.element_description  ?? null,
        is_element: this.toggle_element,
        element_slow_sound: this.element_has_slow_sound ? 1 : 0,
        is_required_script: 0,
        sample_data: null,
        sample_content: null,
        idea: null,
        sample: null,
        can_take_note: null,
        index: this.offsetQuestionInput,
        limit_listen_time: null,
        audio_file_text: null,
        sample_file_text: null,
        limit_time: null,
        direction: null,
        body: this.setBodySaveUniversalQuestion(),
        option_layout: this.optionLayoutTypeInput,
        option_content_type: this.optionContentTypeInput,
        data_option: this.setOptionSaveUniversalQuestion(this.typeQuestionInput),
        answers: [],
      };
      let dataAnswer = {
        option: this.dataAnswer(this.typeQuestionInput).optionAnswer,
        correct: this.dataAnswer(this.typeQuestionInput).correctAnswer,
      };
      data.answers.push(dataAnswer);
      this.is_call_api = true;
      console.log(data);
      // if (this.is_call_api) return;
      if (
          [
              this.exercise_type_constant.SINGLE_CHOICE_ELEMENT,
              this.exercise_type_constant.MATCHING_CARD,
          ].includes(this.typeQuestionInput)
      ) {
        this.createOrUpdateWithFormData(data);
        return;
      }

      await ApiService.post("prep-app/test/draft/question", data)
          .then(function (res) {
            if ([200,202].includes(res.status)) {
              // vm.is_call_api = false;
              vm.handleAfterCreate(data, res);
            }
          })
          .catch(function (error) {
            if (error) {
              vm.dialogQuestionUniversal = false;
              vm.is_call_api = false;
              vm.messError("Có lỗi xảy ra. Vui lòng liên hệ phòng kỹ thuật hỗ trợ");
            }
          });
    },
    async btnEditQuestion() {
      let vm = this;
      let validateForm = this.validateFormQuestion();
      let validateQues = this.validateOptionAndCorrectQuestionByType(this.typeQuestionInput);
      let validatorElement = this.validateElementForm();
      if (!(validateForm && validateQues && validatorElement)) {
        return;
      }
      if (vm.toggle_element) {
        await this.actionUploadElement();
      }

      let question = this.exercise.questions[this.indexQuestion];
      let data = {
        id: question.id,
        exercise_id: this.exercise.id,
        answer_id: this.answer_id,
        title: this.titleQuestionInput,
        explanation: this.explanationQuestionInput ? this.explanationQuestionInput : null,
        exercise_type: this.exercise.exercise_type,
        type: this.typeQuestionInput,
        content: this.contentQuestionInput ? this.contentQuestionInput : null,
        weight: 0,
        element_type: this.type_element,
        element_data: this.getDataByLabelElement(this.type_element),
        element_id: this.toggle_element === false ? null : this.element_id,
        element_description: this.element_description  ?? null,
        is_element: this.toggle_element,
        element_slow_sound: this.element_has_slow_sound ? 1 : 0,
        is_required_script: 0,
        sample_data: null,
        sample_content: null,
        idea: null,
        sample: null,
        can_take_note: null,
        limit_listen_time: null,
        audio_file_text: null,
        sample_file_text: null,
        limit_time: null,
        direction: null,
        body: this.setBodySaveUniversalQuestion(),
        option_layout: this.optionLayoutTypeInput,
        option_content_type: this.optionContentTypeInput,
        data_option: this.setOptionSaveUniversalQuestion(this.typeQuestionInput),
        answers: [],
      };
      let dataAnswer = {
        option: this.dataAnswer(this.typeQuestionInput).optionAnswer,
        correct: this.dataAnswer(this.typeQuestionInput).correctAnswer,
      };
      data.answers.push(dataAnswer);
      this.is_call_api = true;
      if (
          [
            this.exercise_type_constant.SINGLE_CHOICE_ELEMENT,
            this.exercise_type_constant.MATCHING_CARD,
          ].includes(this.typeQuestionInput)
      ) {
        this.createOrUpdateWithFormData(data);
        return;
      }
      await ApiService.put("prep-app/test/draft/question/" + this.question_id, data)
          .then(function (res) {
            if ([200,202].includes(res.status)) {
              if (res.data?.direction) {
                data.direction = res.data.direction;
              }
              if (
                  [
                    vm.exercise_type_constant.SENTENCE_COMPLETION,
                    vm.exercise_type_constant.SENTENCE_SCRAMBLE
                  ].includes(vm.typeQuestionInput)
              ) {
                data.body = JSON.parse(res.data.question.body);
              }
              if (
                  [
                    vm.exercise_type_constant.SINGLE_CHOICE_ELEMENT,
                    vm.exercise_type_constant.MATCHING_CARD,
                    vm.exercise_type_constant.SENTENCE_SCRAMBLE,
                  ].includes(vm.typeQuestionInput)
              ) {
                data.correct_options = res.data.question.correct_options;
                data.options = res.data.options;
              }
              vm.skills[vm.indexSkill].sections[vm.indexSection].exercises[vm.indexExercise].questions[vm.indexQuestion] = data;
              vm.$set(vm.skills);
              vm.is_call_api = false;
              vm.dialogQuestionUniversal = false;
            }
          })
          .catch(function (error) {
            if (error) {
              vm.dialogQuestionUniversal = false;
              vm.is_call_api = false;
              vm.messError("Có lỗi xảy ra. Vui lòng liên hệ phòng kỹ thuật hỗ trợ");
            }
          });
    },
    validateElementForm() {
      let flat = true;
      if (this.toggle_element) {
        if (this.type_element == null) {
          this.$toasted.error("Hãy chọn loại element !!", {theme: "toasted-primary", position: "top-right", duration: 4000,});
          flat = false;
        }
        if (this.type_element === "Video") {
          if (this.video_element_data == null) {
            this.$toasted.error("Bạn chưa chọn tệp tin !!", {theme: "toasted-primary", position: "top-right", duration: 4000,});
            flat = false;
          }
        }
        if (this.type_element === "Image") {
          if (this.element_image_data == null) {
            this.$toasted.error("Bạn chưa chọn tập tin !!", {theme: "toasted-primary", position: "top-right", duration: 4000,});
            flat = false;
          }
          if (this.element_description) {
            if (this.element_description.replaceAll('\n', '').length > 3000) {
              this.$toasted.error("Mô tả ảnh không được nhập quá 3000 ký tự", {theme: "toasted-primary", position: "top-right", duration: 4000,});
              flat = false;
            }
          }
        }
        if (this.type_element === "Audio") {
          if (this.element_audio_data == null) {
            this.$toasted.error("Bạn chưa chọn tệp tin !!", {theme: "toasted-primary", position: "top-right", duration: 4000,});
            flat = false;
          }
        }
        if (this.type_element === "Post") {
          if (this.element_post_data == null) {
            this.$toasted.error("Bạn chưa nhập dữ liệu !!", {theme: "toasted-primary", position: "top-right", duration: 4000,});
            flat = false;
          }
        }
      }
      return flat;
    },
    async actionUploadElement() {
      let vm = this;
      vm.is_call_api = true;
      let formData = new FormData();
      let valueElement = vm.getTypeByLabelElement(vm.type_element);
      if (vm.type_element === "Video") {
        vm.element_id = vm.video_element_id;
        vm.is_call_api = false;
      } else {
        formData.append("element_id", vm.element_id);
        formData.append("type", valueElement);
        if (!this.checkIsElementTypeForUploadFileByTypeTxt(this.type_element)) {
          formData.append("editor", vm.getDataByLabelElement(vm.type_element));
        }
        formData.append("file", vm.getFileByLabelElement(vm.type_element));
        formData.append("description", vm.element_description);
        if (vm.element_has_slow_sound) formData.append("has_slow_sound", vm.element_has_slow_sound ? 1 : 0);
        await ApiService.post("prep-app/test/draft/exercise/upload-element", formData)
        .then(function (response) {
          vm.element_id = response.data.element_code;
          vm.is_call_api = false;
        })
        .catch(function (error) {
          if (error) {
            vm.dialogQuestionUniversal = false;
            vm.is_call_api = false;
            vm.messError('Có lỗi xảy ra. Vui lòng liên hệ phòng kỹ thuật hỗ trợ');
          }
        });
      }
    },
    dataAnswer(exercise_type) {
      let optionAnswer = null,
          correctAnswer = null;

      if (exercise_type === this.exercise_type_constant.SINGLE_CHOICE) {
        optionAnswer = this.optionAnswerSingleChoice;
        correctAnswer = this.correctSingleChoice;
      } else if (exercise_type === this.exercise_type_constant.MULTIPLE_CHOICE) {
        let convert = this.convertInputDataMultipleChoice(
            this.optionAnswerMultipleChoice
        );
        optionAnswer = convert.dataConvertOption;
        correctAnswer = convert.dataConvertCorrect;
      }
      return {
        optionAnswer,
        correctAnswer,
      };
    },
    convertInputDataMultipleChoice(data) {
      let dataConvertOption = [];
      let dataConvertCorrect = [];
      for (let i = 0; i < data.length; i++) {
        dataConvertOption.push(data[i].value);
        if (data[i].checked) {
          dataConvertCorrect.push({
            value: data[i].value,
            index: i,
          });
        }
      }
      return {
        dataConvertOption,
        dataConvertCorrect,
      };
    },
    handleAfterCreate(data, res) {
      let vm = this;
      let checkQuestion = vm.skills[vm.indexSkill].sections[vm.indexSection].exercises[vm.indexExercise].questions;
      if (checkQuestion === undefined || checkQuestion === null) {
        vm.skills[vm.indexSkill].sections[vm.indexSection].exercises[vm.indexExercise].questions = [];
      }
      data.id = res.data.question_id;
      data.answer_id = res.data.answer_id;
      data.direction = res.data.direction;
      if (
          [
            vm.exercise_type_constant.SENTENCE_COMPLETION,
            vm.exercise_type_constant.SENTENCE_SCRAMBLE
          ].includes(vm.typeQuestionInput)
      ) {
        data.body = JSON.parse(res.data.question.body);
      }
      if (
          [
              vm.exercise_type_constant.SINGLE_CHOICE_ELEMENT,
              vm.exercise_type_constant.MATCHING_CARD,
              vm.exercise_type_constant.SENTENCE_SCRAMBLE,
          ].includes(vm.typeQuestionInput)
      ) {
        data.correct_options = res.data.question.correct_options;
        data.options = res.data.options;
      }
      let indexRowQuestion = vm.skills[vm.indexSkill].sections[vm.indexSection].exercises[vm.indexExercise].questions.push(data) - 1;
      for (let indexExer = vm.skills[vm.indexSkill].sections[vm.indexSection].exercises.length - 1; indexExer >= vm.indexExercise + 1; indexExer--) {
        vm.skills[vm.indexSkill].sections[vm.indexSection].exercises[indexExer].offset_question++;
      }
      for (let indexSec = vm.skills[vm.indexSkill].sections.length - 1; indexSec >= vm.indexSection + 1; indexSec--) {
        vm.skills[vm.indexSkill].sections[indexSec].offset_question++;
      }
      vm.skills[vm.indexSkill].sections[vm.indexSection].total_question++;
      vm.skills[vm.indexSkill].sections[vm.indexSection].exercises[vm.indexExercise].total_question++;
      vm.$set(vm.skills);
      vm.is_call_api = false;
      vm.dialogQuestionUniversal = false;
      setTimeout(() => {
        vm.$scrollTo("#question" + vm.indexSkill + vm.indexSection + vm.indexExercise + indexRowQuestion, 1000);
      }, 100);
      // console.log("#question" + vm.indexSkill + vm.indexSection + vm.indexExercise + indexRowQuestion);
    },
    getDataByLabelElement(label) {
      if (label === "Audio") {
        return this.element_audio_data;
      }
      if (label === "Image") {
        return this.element_image_data;
      }
      if (label === "Post") {
        return this.element_post_data;
      }
      if (label === "Video") {
        return this.video_element_data;
      }

      return null;
    },
    getFileByLabelElement(label) {
      if (label === "Image") {
        return this.element_image_file;
      }
      if (label === "Audio") {
        return this.element_audio_file;
      }
      return null;
    },
    messError(mess) {
      this.$toasted.error(mess, {theme: "toasted-primary", position: "top-right", duration: 4000,});
    },
    canUseContentInput() {
      if (
          [
            this.exercise_type_constant.SENTENCE_COMPLETION,
            this.exercise_type_constant.SINGLE_CHOICE_ELEMENT,
            this.exercise_type_constant.SENTENCE_SCRAMBLE,
          ].includes(this.typeQuestionInput))
      {
        return true;
      }
      return false;
    },
    canUseExplanationInput() {
      if (
          [
            this.exercise_type_constant.SINGLE_CHOICE,
            this.exercise_type_constant.MULTIPLE_CHOICE,
            this.exercise_type_constant.SENTENCE_COMPLETION,
            this.exercise_type_constant.SINGLE_CHOICE_ELEMENT,
            this.exercise_type_constant.MATCHING_CARD,
            this.exercise_type_constant.SENTENCE_SCRAMBLE,
          ].includes(this.typeQuestionInput))
      {
        return true;
      }
      return false;
    },
    setBodySaveUniversalQuestion() {
      let body = null;
      if (this.typeQuestionInput === this.exercise_type_constant.SENTENCE_COMPLETION) {
        body = {
          corrects: this.dataCorrectSentenceCompletion,
          obfuscates: this.dataObfuscateSentenceCompletion,
        };
      }

      return body;
    },
    setOptionSaveUniversalQuestion(type) {
      let option = null;
      let vm = this;
      if (type === this.exercise_type_constant.SINGLE_CHOICE_ELEMENT) {
        option = {
          correct_index: this.correctSingleChoiceElementIndex,
          options: this.dataOptionSingleChoiceElement.map((item) => {
            let file = [vm.question_content_type_constant.IMAGE, vm.question_content_type_constant.TEXT_AND_IMAGE].includes(vm.optionContentTypeInput) ? item.image_file : item.audio_file;
            let url = [vm.question_content_type_constant.IMAGE, vm.question_content_type_constant.TEXT_AND_IMAGE].includes(vm.optionContentTypeInput) ? item.image_data : item.audio_data;
            return {
              uuid: item.uuid,
              offset: item.offset,
              text: item.text,
              file: file,
              url: url,
            };
          }),
        };
      }
      if (type === this.exercise_type_constant.MATCHING_CARD) {
        option = {
          column_a: this.dataOptionColumnAMatchingCard.map((item) => {
            let file = [vm.question_content_type_constant.IMAGE, vm.question_content_type_constant.TEXT_AND_IMAGE].includes(vm.typeContentColumnAMatchingCard) ? item.image_file : item.audio_file;
            let url = [vm.question_content_type_constant.IMAGE, vm.question_content_type_constant.TEXT_AND_IMAGE].includes(vm.typeContentColumnAMatchingCard) ? item.image_data : item.audio_data;
            return {
              uuid: item.uuid,
              offset: item.offset,
              text: item.text,
              file: file,
              url: url,
            };
          }),
          content_type_column_a: this.typeContentColumnAMatchingCard,
          column_b: this.dataOptionColumnBMatchingCard.map((item) => {
            let file = [vm.question_content_type_constant.IMAGE, vm.question_content_type_constant.TEXT_AND_IMAGE].includes(vm.typeContentColumnBMatchingCard) ? item.image_file : item.audio_file;
            let url = [vm.question_content_type_constant.IMAGE, vm.question_content_type_constant.TEXT_AND_IMAGE].includes(vm.typeContentColumnBMatchingCard) ? item.image_data : item.audio_data;
            return {
              uuid: item.uuid,
              offset: item.offset,
              text: item.text,
              file: file,
              url: url,
            };
          }),
          content_type_column_b: this.typeContentColumnBMatchingCard,
          corrects: this.dataCorrectMatchingCard,
        };
      }

      if (type === this.exercise_type_constant.SENTENCE_SCRAMBLE) {
        option = {
          corrects: this.dataCorrectSentenceScramble,
          obfuscates: this.dataObfuscateSentenceScramble ?? [],
          words: this.dataWordSentenceScramble,
        };
      }
      return option;
    },
    createOrUpdateWithFormData(data) {
      let vm = this;
      let formData = new FormData();
      if (data.id) {
        formData.append("_method", "PUT");
        formData.append("id", data.id);
      }
      formData.append("exercise_id", data.exercise_id);
      formData.append("type", data.type);
      formData.append("title", data.title);
      formData.append("element_type", data.element_type);
      formData.append("element_data", data.element_data);
      formData.append("is_element", data.is_element);
      if (data.index) formData.append("index", data.index);
      if (data.body) formData.append("body", data.body);
      if (data.content) formData.append("content", data.content);
      if (data.element_id) formData.append("element_id", data.element_id);
      if (data.description) formData.append("description", data.description);
      if (data.explanation) formData.append("explanation", data.explanation);
      if (data.option_layout) formData.append("option_layout", data.option_layout);
      if (data.option_content_type) formData.append("option_content_type", data.option_content_type);
      if (data.data_option) {
        formData.append("data_option", JSON.stringify(data.data_option));
        if (data.type === vm.exercise_type_constant.SINGLE_CHOICE_ELEMENT) {
          for (let i = 0; i < data.data_option.options.length; i++) {
            formData.append('option_file_' + (i + 1), data.data_option.options[i].file);
          }
        }
        if (data.type === vm.exercise_type_constant.MATCHING_CARD) {
          for (let i = 0; i < data.data_option.column_a.length; i++) {
            formData.append('option_file_column_a_' + (i + 1), data.data_option.column_a[i].file);
          }
          for (let i = 0; i < data.data_option.column_b.length; i++) {
            formData.append('option_file_column_b_' + (i + 1), data.data_option.column_b[i].file);
          }
        }
      }

      if (!data.id) {
        ApiService.post("prep-app/test/draft/question", formData)
          .then(function (res) {
            if (res.status === 200) {
              vm.is_call_api = false;
              vm.handleAfterCreate(data, res);
            }
          })
          .catch(function (error) {
            if (error) {
              vm.dialogQuestionUniversal = false;
              vm.is_call_api = false;
              vm.messError('Có lỗi xảy ra. Vui lòng liên hệ phòng kỹ thuật hỗ trợ');
            }
          });
      } else {
        ApiService.post("prep-app/test/draft/question/" + data.id, formData)
          .then(function (res) {
            if (res.status === 200) {
              if (
                  [
                    vm.exercise_type_constant.SINGLE_CHOICE_ELEMENT,
                    vm.exercise_type_constant.MATCHING_CARD,
                  ].includes(vm.typeQuestionInput)
              ) {
                data.correct_options = res.data.question.correct_options;
                data.options = res.data.options;
              }
              vm.skills[vm.indexSkill].sections[vm.indexSection].exercises[vm.indexExercise].questions[vm.indexQuestion] = data;
              vm.$set(vm.skills);
              vm.dialogQuestionUniversal = false;
              vm.is_call_api = false;
            }
          })
          .catch(function (error) {
            if (error) {
              // vm.dialogQuestionUniversal = false;
              vm.is_call_api = false;
              vm.messError('Có lỗi xảy ra. Vui lòng liên hệ phòng kỹ thuật hỗ trợ');
            }
          });
      }
    },
    canConfigSlowSoundElement() {
      if (this.typeQuestionInput === this.exercise_type_constant.SENTENCE_SCRAMBLE) {
        return true;
      }
      return false;
    },
    getNoteForTitle() {
      if (this.typeQuestionInput === this.exercise_type_constant.SINGLE_CHOICE_ELEMENT) {
        return "Dạng 4, 6: Nhập Nghe và tìm từ còn thiếu \n" +
            "Dạng 8: Nhập Chọn hình ảnh đúng \n" +
            "Dạng 10: Nhập Bạn nghe được gì?";
      }
      if (this.typeQuestionInput === this.exercise_type_constant.SENTENCE_SCRAMBLE) {
        return "Nhập tối đa 50 ký tự";
      }
      return '';
    }
  },
}
</script>

<style scoped>
.label_default {
  font-size: 16px;
}
</style>