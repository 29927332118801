<template>
  <div style="width: 100%">
    <div style="width: 100%">
      <v-btn color="#039BE5" style="color: white" @click="openDialogAddOptionSentenceCompletion()">
        <v-icon>mdi-plus</v-icon> Thêm khoảng trống ( mô tả )
      </v-btn>

      <div style="width: 100%" class="pt-8">
        <p class="label_default ">Từ nhiễu</p>

        <template v-for="(obfuscate, i) in dataObfuscateSentenceCompletion">
          <div :key="i" style="width: 70%" class="d-flex">
            <v-text-field
                outlined dense
                class="mt-0"
                label="Từ nhiễu"
                v-model="obfuscate.value"
            ></v-text-field>
            <v-icon class="pb-5" dense large @click="deleteObfuscateSentenceCompletion(i)">mdi-delete</v-icon>
          </div>
        </template>

        <div style="width: 180px" class="d-flex cursor-pointer" @click="addObfuscateSentenceCompletion()">
          <v-btn outlined icon color="sliver">
            <v-icon color="sliver">mdi-plus</v-icon>
          </v-btn>
          <span class="text-h6 mt-1 ml-1">Thêm từ nhiễu</span>
        </div>

      </div>
    </div>

    <v-row justify="center">
      <v-dialog
          v-model="dialogSentenceCompletion"
          persistent
          max-width="600px"
          scrollable
      >
        <v-card>
          <v-card-title>
            <v-col cols="11">
              <p class="headline mb-0">Thêm khoảng trống</p>
            </v-col>
            <v-col cols="1">
              <v-icon @click="dialogSentenceCompletion = false" right
              >mdi-close</v-icon
              >
            </v-col>
          </v-card-title>
          <v-card-text>
            <v-container>
              <p class="text-body-1 red--text font-weight-medium">Nếu khoảng trống có nhiều hơn 1 đáp án đúng, vui lòng thêm đầy đủ các đáp án</p>
              <v-row>
                <v-col cols="12">
                  <template v-for="(correct, i) in optionSentenceCompletion">
                    <div :key="i" class="d-flex">
                      <v-text-field
                          :label="'Correct ' + (i + 1)"
                          :value="correct"
                          @change="changeCorrectValueSentenceCompletion($event, i)"
                      ></v-text-field>
                      <v-btn class="mt-3" @click="deleteCorrectSentenceCompletion(i)">
                        <v-icon>mdi-delete</v-icon>
                      </v-btn>
                    </div>
                  </template>
                </v-col>
                <v-col cols="3">
                  <v-btn large @click="addCorrectSentenceCompletion()">
                    <v-icon>mdi-plus</v-icon> Add Correct
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="red darken-1" text @click="dialogSentenceCompletion = false">Close</v-btn>
            <v-btn color="blue darken-1" outlined @click="btnSaveCorrectSentenceCompletion()">Add</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "SentenceCompeletion",
  props: {
    refContentQuestionUniversal: {
      type: Object,
      default: () => {},
    },
    corrects: {
      type: Array,
      default: () => [],
    },
    obfuscates: {
      type: Array,
      default: () => [{ uuid: null, value: null, offset: 1 }],
    },
  },
  data() {
    return {
      dialogSentenceCompletion: false,
      optionSentenceCompletion: [''],
    };
  },
  computed: {
    dataCorrectSentenceCompletion: {
      get() {
        return this.corrects;
      },
      set(value) {
        this.$emit("setDataCorrectSentenceCompletion", value);
      },
    },
    dataObfuscateSentenceCompletion: {
      get() {
        return this.obfuscates;
      },
      set(value) {
        this.$emit("setDataObfuscateSentenceCompletion", value);
      },
    },
  },
  watch: {

  },
  methods: {
    messError(mess) {
      this.$toasted.error(mess, {theme: "toasted-primary", position: "top-right", duration: 4000,});
    },
    openDialogAddOptionSentenceCompletion() {
      this.optionSentenceCompletion = [''];
      this.dialogSentenceCompletion = true;
    },
    addCorrectSentenceCompletion() {
      this.optionSentenceCompletion.push('');
    },
    validateCorrectSentenceCompletion() {
      let flat = true;
      for (let i = 0; i < this.optionSentenceCompletion.length; i++) {
        if (this.optionSentenceCompletion[i] === "") {
          this.messError("Hãy nhập thông tin cho option " + (i + 1) + " !!");

          flat = false;
        }
      }
      return flat;
    },
    async btnSaveCorrectSentenceCompletion() {
      let validate = this.validateCorrectSentenceCompletion();
      if (!validate) {
        return;
      }
      let insertFirst = "{{";
      let insertLast = "}}";

      let refDescription = this.refContentQuestionUniversal;
      let refCkEditor = refDescription.$refs.editorCustom.$_instance;

      let data_add_option = " " + insertFirst + this.chainCorrectSentenceCompletion(this.optionSentenceCompletion) + insertLast;

      refCkEditor.model.change((writer) => {
        let insertPosition = refCkEditor.model.document.selection.getFirstPosition();
        writer.insertText(data_add_option, insertPosition);
      });

      this.dataCorrectSentenceCompletion = this.transformParagraphToCorrects(refCkEditor.getData());
      refCkEditor.editing.view.focus();
      this.dialogSentenceCompletion = false;
    },
    chainCorrectSentenceCompletion(array) {
      let string = "";
      for (let index = 0; index < array.length; index++) {
        string += array[index].trim() + "//";
      }

      return string.substring(0, string.length - 2);
    },
    changeCorrectValueSentenceCompletion(value, index) {
      this.optionSentenceCompletion.splice(index, 1, value);
    },
    deleteCorrectSentenceCompletion(index) {
      if (this.optionSentenceCompletion.length <= 1) {
        this.messError("Phải có tối thiểu 1 đáp án !");
        return;
      }
      this.optionSentenceCompletion.splice(index, 1);
    },

    addObfuscateSentenceCompletion() {
      this.dataObfuscateSentenceCompletion.push({uuid: null, value: null, offset: this.dataObfuscateSentenceCompletion.length + 1});
    },
    deleteObfuscateSentenceCompletion(index) {
      if (this.dataObfuscateSentenceCompletion.length <= 1) {
        this.messError("Phải có tối thiểu 1 từ nhiễu !");
        return;
      }
      this.dataObfuscateSentenceCompletion.splice(index, 1);
    },

    transformParagraphToCorrects(paragraph) {
      const corrects = [];
      const regex = /{{(.*?)}}/g;
      let match;
      let offset = 1;

      while ((match = regex.exec(paragraph)) !== null) {
        const words = match[1].split('//');
        words.forEach(word => {
          corrects.push({ uuid: null, value: word.trim(), offset: offset++ });
        });
      }

      return corrects;
    }

  },

}
</script>

<style scoped>
.label_default {
  font-size: 16px;
}
</style>